
import { computed, defineComponent } from "vue";
import { formatDate, subTotal, totalInt } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import { Movement, QueryTicketMovementsArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import { useQuery, useResult } from "@vue/apollo-composable";
import { getDiscountValues } from "@/graphql/ticket/ticket";
import TicketRapportDeatilTables from "./TicketRapportDetailTables.vue";

type Data = {
  ticketMovements: Movement[];
};
const QUERY = gql`
    query TicketMovements($id: String!){
        ticketMovements(id: $id) {
            ${MOVEMENT_FIELDS}
            product {
                id
                name
            }
        }
    }
`;
export default defineComponent({
  name: "TicketDetail",
  props: ["ticket", "visible"],
  emits: ["update:visible"],
  components: { TicketRapportDeatilTables },
  setup(props) {
    const { loading, result } = useQuery<Data, QueryTicketMovementsArgs>(
      QUERY,
      { id: props.ticket.parentId || props.ticket.id },
      { fetchPolicy: "no-cache" }
    );
    const movements = useResult(result, [], (res) => res.ticketMovements);

    return {
      subTotal,
      totalInt,
      formatDate,
      currencySymbol: activeActivity.value.currencySymbol,
      movements,
      loading,
      sign: props.ticket?.parentId ? -1 : 1,
      resume: computed(() => getDiscountValues(props.ticket)),
    };
  },
});
