import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TicketRapportDeatilTables = _resolveComponent("TicketRapportDeatilTables")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:visible', $event))),
    visible: _ctx.visible,
    header: _ctx.$t('ticket.details'),
    style: {"min-width":"50vw"},
    draggable: ""
  }, {
    footer: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_TicketRapportDeatilTables, {
        loading: _ctx.loading,
        ticket: { ..._ctx.ticket, movements: _ctx.movements }
      }, null, 8, ["loading", "ticket"])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}